<script>
import isBnn from '@/config/isBnn'
export default {
  mounted() {
    window.location.href = isBnn
      ? 'https://html-content.s3.ap-southeast-1.amazonaws.com/product-feed/Product-Feed-iphone-15-Bnn.csv'
      : 'https://html-content.s3.ap-southeast-1.amazonaws.com/product-feed/Product-Feed-iphone-15-Studio7.csv'
  },
}
</script>
